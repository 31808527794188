html, body {
  box-sizing: border-box;
  margin: 0;
  background-color: black;
}

@font-face {
  font-family: 'Graviton';
  src: local('Graviton-LedRegular'),
  url(./fonts/Graviton-LedRegular.otf)
}

@font-face {
  font-family: 'Ozone';
  src: local('Ozone'),
    url(./fonts/Ozone.ttf)
}

@font-face {
  font-family: 'Gidole';
  src: local('Gidole'),
    url(./fonts/Gidole-Regular.otf)
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'),
    url(./fonts/DINRegular.ttf)
}

@font-face {
    font-family: 'dinregular';
    src: url('./fonts/dinregular-webfont.woff2') format('woff2'),
         url('./fonts/dinregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

h1 {
  font-family: 'Graviton',
  Arial,
  Helvetica,
  sans-serif;
}